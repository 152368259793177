import React, { useState, useCallback, useEffect } from 'react';
import { openDB } from 'idb';
import {
  Box, 
  Button, 
  Dialog, 
  DialogActions, 
  DialogContent, 
  DialogTitle, 
  List, 
  ListItem, 
  ListItemIcon,
  ListItemText, 
  IconButton, 
  Typography, 
  Tooltip
} from '@mui/material';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import DeleteIcon from '@mui/icons-material/Delete';
import CloseIcon from '@mui/icons-material/Close';
import { useDropzone } from 'react-dropzone';
import SelectedFilesPopup from './SelectedFilesPopup';
import CryptoJS from 'crypto-js';

const STATUS = {
  COMPLETED: 'Successfully Uploaded',
  FAILED: 'Failed',
};

const AddNewCardsPopup = ({ open, handleClose, uploadType, uploadStatus }) => {
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [showFilesPopup, setShowFilesPopup] = useState(false);
  const [showUploadBox, setShowUploadBox] = useState(true);

  const initDB = async () => {
    return await openDB('fileStore', 1, {
      upgrade(db) {
        if (!db.objectStoreNames.contains('files')) {
          db.createObjectStore('files', { keyPath: 'id', autoIncrement: true });
        }
      },
    });
  };

  useEffect(() => {
    const fetchFiles = async () => {
      const db = await initDB();
      const files = await db.getAll('files');
      setUploadedFiles(files);
      checkFilesLimit(files);
    };
  
    fetchFiles();
  }, [uploadedFiles]);

  useEffect(() => {
    if (uploadStatus === STATUS.COMPLETED) {
      const clearFiles = async () => {
        setUploadedFiles([]);
        const db = await initDB();
        const tx = db.transaction('files', 'readwrite');
        const store = tx.objectStore('files');
        const allKeys = await store.getAllKeys();
        allKeys.forEach(key => store.delete(key));
        await tx.done;
        setShowUploadBox(true);
      };

      clearFiles();
    }
  }, [uploadStatus]);

  const saveFiles = async (files) => {
    try {
      const db = await initDB();
      const tx = db.transaction('files', 'readwrite');
      const store = tx.objectStore('files');
      await Promise.all(files.map(file => store.put(file)));
      await tx.done;
      // console.log('Files saved to IndexedDB:', files);  
    } catch (error) {
      // console.error('An error occurred while saving files to IndexedDB:', error);
    }
  };

  const calculateMD5 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (e) => {
        const wordArray = CryptoJS.lib.WordArray.create(e.target.result);
        const md5 = CryptoJS.MD5(wordArray).toString();
        resolve(md5);
      };
      reader.onerror = (error) => reject(error);
      reader.readAsArrayBuffer(file);
    });
  };

  const getBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      // console.log(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = reject;
      reader.readAsDataURL(file);
    });
  };

  const checkFilesLimit = (files) => {
    const zipFileUploaded = files.some(file => file.type === 'application/zip');
    const imageFilesCount = files.filter(file => file.type && file.type.startsWith('image/')).length;

    if (zipFileUploaded || imageFilesCount >= 100) {
      setShowUploadBox(false);
    }
  };

  const onDrop = useCallback(async (acceptedFiles) => {
    const zipFileUploaded = uploadedFiles.some(file => file.type === 'application/zip');
    let updatedFiles = [...uploadedFiles];

    for (const file of acceptedFiles) {
      if (file.type === 'application/zip') {
        if (zipFileUploaded || updatedFiles.some(f => f.type !== 'application/zip')) {
          alert('You cannot upload more than one ZIP file or upload a ZIP file after uploading image files.');
          continue;
        } else {
          const md5 = await calculateMD5(file);
          const timestamp = Date.now();
          const newFileName = `${md5}_${timestamp}`;
          const base64 = await getBase64(file);

          const newFile = {
            originalName: file.name,
            name: newFileName,
            md5,
            timestamp,
            base64,
            type: 'application/zip',
            size: file.size
          };

          updatedFiles = [...updatedFiles, newFile];
          continue;
        }
      }

      if (updatedFiles.filter(f => f.type === 'application/zip').length === 0 &&
        updatedFiles.filter(f => f.type && f.type.startsWith('image/')).length >= 100) {
        alert('Cannot upload more than 100 images.');
        continue;
      }

      const md5 = await calculateMD5(file);
      const timestamp = Date.now();
      const newFileName = `${md5}_${timestamp}`;

      if (uploadedFiles.some(uploadedFile => uploadedFile.md5 === md5)) {
        alert(`The file "${file.name}" has already been uploaded.`);
        continue;
      }

      const base64 = await getBase64(file);

      let newFile = {
        originalName: file.name,
        name: newFileName,
        md5,
        timestamp,
        base64,
        type: file.type,
        size: file.size
      };

      updatedFiles = [...updatedFiles, newFile];
    }

    setUploadedFiles(updatedFiles);
    saveFiles(updatedFiles);
    checkFilesLimit(updatedFiles);
  }, [uploadedFiles]);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      'application/zip': ['.zip'],
      'image/*': ['.jpg', '.jpeg', '.png']
    },
    multiple: true,
  });
  const handleDelete = async (fileToDelete) => {
    const updatedFiles = uploadedFiles.filter(file => file.name !== fileToDelete.name);
    // console.log('Updated Files:', updatedFiles);
    setUploadedFiles(updatedFiles);
    try {
      const db = await initDB();
      const tx = db.transaction('files', 'readwrite');
      const store = tx.objectStore('files');
      await store.delete(fileToDelete.id); 
      await tx.done;
      // console.log('File removed from IndexedDB:', fileToDelete.id);
      if (updatedFiles.length === 0) {
        setShowUploadBox(true);
      }
    } catch (error) {
      // console.error('Error deleting file from IndexedDB:', error);
    }
  };  

  const handleNext = () => {
    setShowFilesPopup(true);
    handleClose();
  };

  const handleFilesPopupClose = () => {
    setShowFilesPopup(false);
  };

  return (
    <>
      <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
        <DialogTitle sx={{ position: 'relative', textAlign: 'center' }}>
          Add New Cards
          <IconButton
            onClick={handleClose}
            sx={{
              position: 'absolute',
              top: 8,
              right: 8,
              color: 'grey.500',
              '&:hover': {
                color: 'black',
              },
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          {showUploadBox && (
            <>
              <Box
                {...getRootProps()}
                sx={{
                  border: '2px dashed #bdbdbd',
                  padding: '20px',
                  textAlign: 'center',
                  borderRadius: '8px',
                  marginBottom: '20px',
                  cursor: 'pointer',
                }}
              >
                <input {...getInputProps()} />
                <UploadFileIcon sx={{ fontSize: 40, color: "#808080" }} />
                <p>
                  {uploadType === 'mainPages'
                    ? 'Drag & drop Main Pages only here'
                    : 'Drag & drop Front and Back Sequence Pages here'}
                </p>
                <Button variant="contained" component="span" sx={{ marginTop: '20px' }}>
                  Choose files
                </Button>
              </Box>
              <Typography variant="subtitle1" sx={{ fontWeight: 'bold', marginBottom: '8px' }}>
                Instructions:
              </Typography>
              <Box sx={{
                backgroundColor: '#f5f5f5',
                padding: '15px',
                borderRadius: '8px',
                marginBottom: '20px',
                border: '0.5px solid #e0e0e0',
                textAlign: 'center',
              }}>
                <Typography
                  variant="body2"
                  component="div"
                  sx={{ display: 'inline-block', textAlign: 'left' }}
                >
                  <ul style={{ paddingLeft: '40px', textAlign: 'left' }}>
                    {uploadType === 'mainPages' ? (
                      <>
                        <li>
                          Upload multiple business cards as a ZIP file containing only front-side images, or select individual images
                          (JPEG, PNG, TIFF) to upload one by one. After uploading, review and confirm details on the Drafts page.
                        </li>
                        <li>
                          Ensure each image or ZIP file uploaded contains only clear, front-side views of business cards. Avoid including
                          any additional files or unrelated images to maintain accuracy in processing.
                        </li>
                      </>
                    ) : (
                      <>
                        <li>
                          Upload the front and back images of each card in sequence as a ZIP file or individual images. Ensure the correct order.
                        </li>
                        <li>
                          Sequence images should be named or numbered correctly to maintain the correct front-back order.
                        </li>
                      </>
                    )}
                  </ul>
                </Typography>
              </Box>
            </>
          )}

          {uploadedFiles.length > 0 && (
            <Typography variant="h6" gutterBottom>
              Selected Files
            </Typography>
          )}
          <List>
            {uploadedFiles.map((file, index) => (
              <ListItem key={index}>
                <Box
                  sx={{
                    border: '0.5px solid #e0e0e0',
                    borderRadius: '10px',
                    padding: '8px',
                    display: 'flex',
                    alignItems: 'center',
                    width: '100%',
                  }}
                >
                  <ListItemIcon>
                    {file.type === 'application/zip' ? (
                      <InsertDriveFileIcon />
                    ) : (
                      <img
                        src={file.base64}
                        alt={file.name}
                        style={{ width: 40, height: 40, objectFit: 'cover' }}
                      />
                    )}
                  </ListItemIcon>
                  <ListItemText primary={`${file.originalName}`} /> {/* Print file type */}
                  <Tooltip title="Delete">
                    <IconButton
                      edge="end"
                      aria-label="delete"
                      onClick={() => handleDelete(file)}
                      sx={{
                        color: 'inherit',
                        '&:hover': {
                          color: 'red',
                        },
                      }}
                    >
                      <DeleteIcon
                        sx={{
                          '&:hover': {
                            color: 'red',
                          },
                        }}
                      />
                    </IconButton>
                  </Tooltip>
                </Box>
              </ListItem>
            ))}
          </List>
        </DialogContent>
        <DialogActions style={{ display: 'flex', justifyContent: 'center', padding: '16px' }}>
          <Button onClick={handleClose} color="primary" style={{ marginRight: '8px' }}>
            Cancel
          </Button>
          <Button onClick={handleNext} variant="contained" color="primary" disabled={uploadedFiles.length === 0}>
            Next
          </Button>
        </DialogActions>
      </Dialog>
      {showFilesPopup && (
        <SelectedFilesPopup
          open={showFilesPopup}
          handleClose={handleFilesPopupClose}
          uploadedFiles={uploadedFiles}
          handleDelete={handleDelete}
          uploadType={uploadType}
        />
      )}
    </>
  );
};

export default AddNewCardsPopup;