const baseUrl = process.env.REACT_APP_BACKEND_BASE_URL;

const path = {
  loginUrl: `${baseUrl}/login`,
  addUser: `${baseUrl}/user`,
  resetAgentPassword: `${baseUrl}/user/reset-password`,
  resetAdminPassword: `${baseUrl}/user/reset-admin-password`,
  getUsers: `${baseUrl}/user`,
  getUserCategory: `${baseUrl}/user-category`,
  addUserCategory: `${baseUrl}/user-category`,
  updateUser: `${baseUrl}/user/`,
  getCards: `${baseUrl}/card/get-cards`,
  updateCard: `${baseUrl}/card/`,
  topEmployeeChart: `${baseUrl}/card/charts/top-five-employees`,
  cardScanDistributionChart: `${baseUrl}/card/charts/card-scan-distribution`,
  getCardAggregation: `${baseUrl}/card/aggregation`,
  markForImprovement:`${baseUrl}/feedback`
};

module.exports = {
  path,
};
