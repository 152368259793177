import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography, RadioGroup, FormControlLabel, Radio } from '@mui/material';

const SelectUploadTypePopup = ({ open, handleClose, handleSelection }) => {
  const [selectedOption, setSelectedOption] = useState('mainPages');

  const handleOptionChange = (e) => {
    setSelectedOption(e.target.value);
  };

  const handleConfirm = () => {
    handleSelection(selectedOption);
    handleClose();
  };  
  return (
    <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
      <DialogTitle style={{ textAlign: 'center' }}>Select Upload Type</DialogTitle>
      <DialogContent>
        <Typography variant="body1" gutterBottom>
          Please select the type of cards you are uploading:
        </Typography>
        <RadioGroup value={selectedOption} onChange={handleOptionChange}>
          <FormControlLabel value="mainPages" control={<Radio />} label="Main Pages Only" />
          <FormControlLabel value="frontBack" control={<Radio />} label="Front and Back Sequence" />
        </RadioGroup>
      </DialogContent>
      <DialogActions style={{ display: 'flex', justifyContent: 'center', padding: '16px' }}>
        <Button onClick={handleClose} color="primary" style={{ marginRight: '8px' }}>
          Cancel
        </Button>
        <Button onClick={handleConfirm} variant="contained" color="primary">
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SelectUploadTypePopup;
