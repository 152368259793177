const _ = require("lodash");

const createdataToUpdateObject = (data, initialData) => {
    const dataToUpdate = {};

    Object.keys(data).forEach(field => {
        if (Array.isArray(data[field]) || typeof data[field] === 'object') {
            if (JSON.stringify(data[field]) !== JSON.stringify(initialData?.[field])) {
                dataToUpdate[field] = data[field];
            }
        } else {
            if (data[field] !== initialData?.[field]) {
                dataToUpdate[field] = data[field];
            }
        }
    });

    return dataToUpdate;
};

function formatDate(date) {
    let d = date.getDate().toString().padStart(2, '0');
    let m = (date.getMonth() + 1).toString().padStart(2, '0');
    return `${d}/${m}`;
}

function getDatesList(minDate, maxDate) {
    let dates = [];

    let currentDate = new Date(minDate);
    while (currentDate <= maxDate) {
        dates.push(formatDate(currentDate));
        currentDate.setDate(currentDate.getDate() + 1);
    }
    return dates;
};

function aggregateDatatoDateFromTimestamp(data, minDate, maxDate) {
    try {
        let aggregatedData = {};
        for (const timestamp of data) {
            const date = new Date(new Date(parseInt(timestamp)).setHours(0, 0, 0, 0));
            const dateKey = formatDate(date);
            // console.log(`DATE: ${dateKey}, TIMESTAMP: ${timestamp}`);
            if (aggregatedData[dateKey]) {
                aggregatedData[dateKey]++;
            } else {
                aggregatedData[dateKey] = 1;
            }
            if(!minDate || date < minDate) {
                minDate = date;
            }
            if(!maxDate || date > maxDate) {
                maxDate = date;
            }
        }
        return {
            aggregatedData,
            minDate,
            maxDate
        }
    } catch (error) {
        throw new Error(`Error occurred in aggregateDatatoDateFromTimestamp: ${error}`);
    }
}

const updateSelectedFields = (updatedCard, initialCard) => {
    const card = _.cloneDeep(initialCard);
    let fieldsToUpdate = [
        "person_name",
        "company_name",
        "designation",
        "categories",
        "notes",
        "isBookmarked",
        "emails",
        "mobile_numbers",
        "address",
    ];
    Object.keys(updatedCard).forEach(field => {
        if (fieldsToUpdate.includes(field)){
            card[field] = updatedCard[field];
        }
    });
    return card;
}

module.exports = {
    createdataToUpdateObject,
    getDatesList,
    aggregateDatatoDateFromTimestamp,
    updateSelectedFields
}