import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Box, 
  Button, 
  Dialog, 
  DialogActions, 
  DialogContent, 
  DialogTitle, 
  List, 
  ListItem, 
  IconButton,
  ListItemText, 
  Typography, 
  Tooltip, 
  Divider, 
  ListItemIcon,
  CircularProgress
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import CloseIcon from '@mui/icons-material/Close';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import UploadStatusPopup from './UploadStatusPopup';
import AddNewCardsPopup from './AddNewCardsPopup';
import Cookies from 'js-cookie';
import { openDB } from 'idb';

const SelectedFilesPopup = ({ open, handleDelete, uploadType, uploadedFiles, handleClose }) => {
  const [storedFiles, setStoredFiles] = useState([]);
  const [uploadStatusOpen, setUploadStatusOpen] = useState(false);
  const [addNewCardsOpen, setAddNewCardsOpen] = useState(false);
  const [uploadStatus, setUploadStatus] = useState(null);
  const [failedFiles, setFailedFiles] = useState([]);
  const [popupWidth, setPopupWidth] = useState('600px');
  const [popupHeight, setPopupHeight] = useState('auto');
  const [selectedImage, setSelectedImage] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchStoredFiles = async () => {
    setLoading(true);
      try {
        const db = await openDB('fileStore', 1, {
          upgrade(db) {
            if (!db.objectStoreNames.contains('files')) {
              db.createObjectStore('files', { keyPath: 'id', autoIncrement: true });
            }
          },
        });

        const tx = db.transaction('files', 'readonly');
        const store = tx.objectStore('files');
        const files = await store.getAll();
        if (Array.isArray(files)) {
          setStoredFiles(files);
        }
      } catch (error) {
        // console.error('Error retrieving files from IndexedDB:', error);
      }finally {
        setLoading(false);  
      }
    };

    if (open) {
      fetchStoredFiles();
    }
  }, [open]);

  const prepareJsonData = (files) => ({
    files: files
      .filter(file => file.base64)
      .map(file => ({
        originalName: file.originalName,
        name: file.name,
        base64: file.base64,
        size: file.size,
      })),
  });

  const determineImageCount = (uploadType) => {
    let imageCount = 2;

    if (uploadType === 'mainPages') {
      imageCount = 1;
    } else if (uploadType === 'frontBack') {
      imageCount = 2;
    }

    return imageCount;
  };

  const handleNext = async () => {
    try {
      const fileType = storedFiles.some(file => file.type === 'application/zip') ? 'zip' : 'image';
      const imageCount = determineImageCount(uploadType);
      const jsonData = prepareJsonData(storedFiles);
      const dataToSend = {
        ...jsonData,
        file_type: fileType,
        image_count: imageCount,
      };
      
      const token = Cookies.get('token');
      if (!token) {
        throw new Error('No token found');
      }
      setStoredFiles(prevFiles => prevFiles.map(file => ({ ...file, loading: true })));
      const response = await axios.post('https://apiv2.hayashirsha.com/bulk-upload', dataToSend, {
        headers: {
          'Authorization': token,
          'Content-Type': 'application/json',
        },
      });
      // console.log(dataToSend);
      setUploadStatus(response.data.status);
      setUploadStatusOpen(true);
    } catch (error) {
      // console.error('Error uploading files:', error);
      setUploadStatus(false);
      setUploadStatusOpen(true);
    }
    finally {
      setStoredFiles(prevFiles => prevFiles.map(file => ({ ...file, loading: false })));
    }
  };

  const handleBack = () => {
    setAddNewCardsOpen(true);
  };

  const closeAddNewCards = () => {
    setAddNewCardsOpen(false);
  };

  const formatFileSize = (size) => {
    if (size === undefined) return 'Unknown size';
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    if (size === 0) return '0 Byte';
    const i = parseInt(Math.floor(Math.log(size) / Math.log(1024)));
    return Math.round(size / Math.pow(1024, i), 2) + ' ' + sizes[i];
  };

  const retryUpload = (file) => {
    // console.log(`Retrying upload for file: ${file.name}`);
    const uploadFile = async (file) => {
      try {
        const formData = new FormData();
        formData.append('file', file);

        const response = await fetch('https://apiv2.hayashirsha.com/bulk-upload', {
          method: 'POST',
          body: formData,
        });

        if (!response.ok) {
          throw new Error('Upload failed');
        }
        // console.log('Upload successful');
        setFailedFiles(prevFiles => prevFiles.filter(f => f !== file));
      } catch (error) {
        // console.error('Upload failed', error);
      }
    };

    uploadFile(file);
  };


  const handleDeleteFile = async (file) => {
    const db = await openDB('fileStore', 1);
    const tx = db.transaction('files', 'readwrite');
    const store = tx.objectStore('files');
    await store.delete(file.id); 
    await tx.done;

    const updatedFiles = storedFiles.filter(storedFile => storedFile.id !== file.id);
    setStoredFiles(updatedFiles);
    if (handleDelete) {
      handleDelete(file);
    }
  };

 const handleThumbnailClick = (file) => {
    setSelectedImage(file.base64);
  };

  const closeImagePopup = () => {
    setSelectedImage(null);
  };

  useEffect(() => {
    if (uploadStatusOpen) {
      setPopupWidth('0px'); 
      setPopupHeight('0px');
    } else {
      setPopupWidth('600px');
      setPopupHeight('auto'); 
    }
  }, [uploadStatusOpen]);
  return (
    <>
      <Dialog open={open} onClose={handleClose} maxWidth="xl"
        fullWidth={false}
        PaperProps={{
          style: {
            width: popupWidth,
            height: popupHeight,
          },
        }}>
        <DialogTitle sx={{ position: 'relative', textAlign: 'center' }}>
          <Typography variant="h6">Selected Files</Typography>
          <IconButton
            onClick={handleClose}
            sx={{
              position: 'absolute',
              top: 8,
              right: 8,
              color: 'grey.500',
              '&:hover': {
                color: 'black',
              },
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          {loading ? (  
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '200px' }}>
              <CircularProgress />
            </Box>
          ) : 
          storedFiles.length > 0 ? (
            <List sx={{ backgroundColor: '#f5f5f5', padding: '15px', borderRadius: '8px', maxHeight: '400px', overflowY: 'auto' }}>
              {storedFiles.map((file) => (
                <React.Fragment key={file.id}>
                  <ListItem
                    sx={{
                      border: '1px solid #e0e0e0',
                      borderRadius: '8px',
                      marginBottom: '8px',
                      padding: '10px',
                      backgroundColor: '#ffffff',
                      display: 'flex',
                      alignItems: 'flex-start',
                      flexDirection: 'column',
                    }}
                  >
                    <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                      <ListItemIcon>
                        {file.loading ? (
                          <CircularProgress size={50} sx={{ color: '#1976d2' }} />
                        ) : file.type === 'application/zip' ? (
                          <InsertDriveFileIcon sx={{ fontSize: 50, color: '#808080' }} />
                        ) : (
                          <img
                            src={file.base64}
                            alt={file.originalName}
                            style={{ width: '35px', height: '35px', objectFit: 'cover', borderRadius: '4px' }}
                            onClick={() => handleThumbnailClick(file)}
                          />
                        )}
                      </ListItemIcon>
                      <ListItemText
                        primary={file.originalName}
                        secondary={formatFileSize(file.size)}
                        sx={{ marginLeft: 2 }}
                      />
                      <Tooltip title="Delete">
                        <IconButton
                          edge="end"
                          aria-label="delete"
                          onClick={() => handleDeleteFile(file)}
                          sx={{
                            '&:hover': {
                              color: 'red',
                            },
                          }}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  </ListItem>
                  {file.id < storedFiles.length - 1 && <Divider />}
                </React.Fragment>
              ))}
            </List>

          ) : (
            <Typography variant="body1">No files stored.</Typography>
          )}
        </DialogContent>
        <DialogActions style={{ justifyContent: 'center' }}>
          <Button onClick={handleBack} color="primary" style={{ marginRight: '8px' }}>
            Cancel
          </Button>
          <Button variant="contained" color="primary" onClick={handleNext}>
            Next
          </Button>
        </DialogActions>
      </Dialog>
     {uploadStatusOpen &&
      <UploadStatusPopup
        open={uploadStatusOpen}
        handleClose={handleClose}
        uploadedFiles={storedFiles}
        handleDelete={handleDelete}
        uploadStatus={uploadStatus}
        retryUpload={retryUpload}
        uploadType={uploadType}
      />}
       <Dialog open={!!selectedImage} onClose={closeImagePopup} maxWidth="md">
        <DialogContent>
          {selectedImage && <img src={selectedImage} alt="Full view" style={{ width: '100%', height: 'auto', borderRadius: '8px' }} />}
        </DialogContent>
        <DialogActions>
          <Button onClick={closeImagePopup} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <AddNewCardsPopup
        uploadStatus={uploadStatus}
        open={addNewCardsOpen}
        handleClose={closeAddNewCards}
      />
    </>
  );
};

export default SelectedFilesPopup;
