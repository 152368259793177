import React, { useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import axios from "axios";
import { Chip, FormControl, InputLabel, MenuItem, OutlinedInput, Select, TextField, IconButton, Button, Dialog } from "@mui/material";
import { Paper, Grid, Typography } from "@mui/material";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Cookies from 'js-cookie';

function MarkForImprovementPopup({ isPopupOpen, handlePopupClose, data=[], setIsMarkedForImprovement }) {
    const [feedbackMessage, setFeedbackMessage] = useState('');
    const [reason, setReason] = useState([]);
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const scanId = data.length > 0 ? data[0]._id : null;
    const token = Cookies.get('token');
    // console.log(scanId);
    // console.log(data);

    useEffect(() => {
        if (isPopupOpen) {
            setFeedbackMessage('');
            setReason([]);
        }
    }, [isPopupOpen]);

    const handleReasonChange = (e) => {
        setReason(e?.target?.value);
        setDropdownOpen(false);
    };

    const handleDropdownOpen = () => {
        setDropdownOpen(true);
    };

    const handleSubmitFeedback = async () => {
        if (reason.length === 0) {
            toast.error('Please select at least one reason.', {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
            return;
        }

        setIsMarkedForImprovement(true);
        try {
            const payload = {
                feedback_message: feedbackMessage,
                reason: reason,
                resolved: false,
                scan_id: scanId, 
            };

            await axios.post(
                "https://apiv2.hayashirsha.com/feedback", 
                payload, 
                {
                  headers: {
                    Authorization: token, 
                    'Content-Type': 'application/json',
                  }
                }
              );              

            toast.success('Feedback sent successfully!', {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
            handlePopupClose();
        } catch (error) {
            toast.error('Failed to send feedback.', {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
            handlePopupClose();
        }
    };

    return (
        <>
            <Dialog
                open={isPopupOpen}
                onClose={handlePopupClose}
                maxWidth="xs"
                fullWidth
                PaperProps={{
                    style: {
                        width: 'auto',
                        maxWidth: 'calc(100vw - 32px)',
                    },
                }}
            >
                <Paper
                    sx={{
                        overflow: "hidden",
                        border: "1px solid #d0d0d0",
                        padding: "15px",
                        margin: "auto",
                        display: 'flex',
                        flexDirection: 'column',
                        position: 'relative',
                    }}
                >
                    <IconButton
                        aria-label="close"
                        onClick={handlePopupClose}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: 'grey',
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                    <Typography variant="h6" align="center" gutterBottom sx={{
                        padding: "15px 0", fontWeight: "bold"
                    }}>
                        Mark for Improvement
                    </Typography>
                    <form style={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
                        <Grid container direction="column" spacing={1} style={{ flexGrow: 1 }}>
                            <Grid item>
                                <Typography
                                    variant="subtitle2"
                                    gutterBottom
                                    sx={{ color: "#000000" }}
                                >
                                    Comment
                                    <span style={{ color: "#808080" }}> (optional)</span>
                                </Typography>
                                <TextField
                                    name="comment"
                                    type="text"
                                    placeholder="Describe the improvement needed..."
                                    required
                                    fullWidth
                                    multiline
                                    rows={4}
                                    sx={{
                                        bgcolor: "#f5f5f5",
                                        marginTop: '10px',
                                        '& .MuiInputBase-root': {
                                            height: 'auto',
                                        },
                                        '& textarea': {
                                            minHeight: '10px',
                                        }
                                    }}
                                    onChange={(e) => setFeedbackMessage(e.target.value)}
                                />
                            </Grid>
                            <Grid item>
                                <Typography
                                    variant="subtitle2"
                                    gutterBottom
                                    sx={{ color: "#000000" }}
                                >
                                    Reason For Improvement
                                </Typography>
                                <FormControl fullWidth sx={{ bgcolor: "#f5f5f5" }}>
                                    <InputLabel id="reason-label">Select the reason(s)</InputLabel>
                                    <Select
                                        labelId="reason-label"
                                        label="Select the reason(s)"
                                        id="reason"
                                        name="reason"
                                        multiple
                                        required
                                        value={reason}
                                        onChange={handleReasonChange}
                                        input={<OutlinedInput label="Select the reason(s)" />}
                                        renderValue={(selected) => (
                                            <div style={{ display: 'flex', flexWrap: 'wrap', gap: 4 }}>
                                                {selected.map((value) => (
                                                    <Chip key={value} label={value} />
                                                ))}
                                            </div>
                                        )}
                                        MenuProps={{
                                            PaperProps: {
                                                style: {
                                                    maxHeight: 300,
                                                },
                                            },
                                            anchorOrigin: {
                                                vertical: "bottom",
                                                horizontal: "left",
                                            },
                                            transformOrigin: {
                                                vertical: "top",
                                                horizontal: "left",
                                            },
                                            getcontentanchorel: null,
                                        }}
                                        open={dropdownOpen}
                                        onOpen={handleDropdownOpen}
                                        onClose={() => setDropdownOpen(false)}
                                    >
                                        <MenuItem value="Missing Information">Missing Information</MenuItem>
                                        <MenuItem value="Incorrect Information">Incorrect Information</MenuItem>
                                        <MenuItem value="Edit problem">Edit problem</MenuItem>
                                        <MenuItem value="Copy issues">Copy issues</MenuItem>
                                        <MenuItem value="Others">Others</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item container justifyContent="center" sx={{ marginTop: "10px", columnGap: "15px" }}>
                                <Button onClick={handlePopupClose} style={{ color: "#000000", fontWeight: "bold", width: "150px" }}>
                                    Cancel
                                </Button>
                                <Button variant="contained" color="primary" type="button" onClick={handleSubmitFeedback} style={{ width: "150px" }}>
                                    Submit
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                </Paper>
            </Dialog>
            <ToastContainer />
        </>
    );
}

export default MarkForImprovementPopup;
