import React, { useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import OutlinedInput from "@mui/material/OutlinedInput";
import Chip from "@mui/material/Chip";
import CloseIcon from "@mui/icons-material/Close";
import { IconButton, TextField, Typography } from "@mui/material";
import ToggleOffIcon from "@mui/icons-material/ToggleOff";
import ToggleOnIcon from "@mui/icons-material/ToggleOn";
import _ from "lodash";
import { cardType } from "../../../contants";

const FilterPopup = ({
  filterView,
  setFilterView,
  userCategories,
  usersMap,
  request,
  handleRequestChange,
  handleSubmit,
  initialRequest,
  previousFilters,
  setRequest,
}) => {
  const [useDateRange, setUseDateRange] = useState(false);
  const [salesRepOpen, setSalesRepOpen] = useState(false);
  const [cardCategoryOpen, setCardCategoryOpen] = useState(false);
  const [typeOpen, setTypeOpen] = useState(false);

  const checkForResetFilter = () => {
    if (_.isEqual(previousFilters, initialRequest)) {
      setRequest(initialRequest);
      return false;
    }
    return true;
  };

  const handleApplyFilterDisabled = () => {
    if (
      _.isEmpty(request?.dateFilter?.startDate) &&
      _.isEmpty(request?.dateFilter?.endDate) &&
      _.isEmpty(request?.srFilter) &&
      _.isEmpty(request?.cardCategoryFilter) &&
      _.isEmpty(request?.typeFilter)
    ) {
      return true;
    }
    return false;
  };

  return (
    <div>
      <Dialog
        open={filterView}
        onClose={(e) => {
          e.preventDefault();
          setFilterView(false);
          handleRequestChange("filterCancel");
        }}
      >
        <DialogTitle>
          Filter Options
          <IconButton
            aria-label="close"
            onClick={(e) => {
              e.preventDefault();
              setFilterView(false);
              handleRequestChange("filterCancel");
            }}
            sx={{
              position: "absolute",
              right: 8,
              top: 9,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <FormControl fullWidth margin="normal">
            <InputLabel id="sales-rep-label">Sales Representatives</InputLabel>
            <Select
              labelId="sales-rep-label"
              multiple
              value={request?.srFilter}
              onChange={(e) => {
                e.preventDefault();
                handleRequestChange("srFilter", e.target.value);
                setSalesRepOpen(false);
              }}
              input={<OutlinedInput label="Sales Representatives" />}
              renderValue={(selected) => (
                <div style={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                  {selected?.map((key) => (
                    <Chip key={key} label={usersMap?.[key]?.displayName || ""} />
                  ))}
                </div>
              )}
              MenuProps={{
                PaperProps: {
                  style: {
                    maxHeight: 300,
                  },
                },
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left",
                },
                transformOrigin: {
                  vertical: "top",
                  horizontal: "left",
                },
                getcontentanchorel: null,
              }}
              open={salesRepOpen}
              onOpen={() => setSalesRepOpen(true)}
              onClose={() => setSalesRepOpen(false)}
            >
              {usersMap &&
                Object.keys(usersMap)?.map((key) => (
                  <MenuItem key={key} value={key}>
                    {usersMap?.[key]?.displayName || ""}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>

          <FormControl fullWidth margin="normal">
            <InputLabel id="card-category-label">Card Categories</InputLabel>
            <Select
              labelId="card-category-label"
              multiple
              value={request?.cardCategoryFilter}
              onChange={(e) => {
                e.preventDefault();
                handleRequestChange("cardCategoryFilter", e.target.value);
                setCardCategoryOpen(false);
              }}
              input={<OutlinedInput label="Card Categories" />}
              renderValue={(selected) => (
                <div style={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                  {selected?.map((value, index) => (
                    <Chip key={index} label={value} />
                  ))}
                </div>
              )}
              MenuProps={{
                PaperProps: {
                  style: {
                    maxHeight: 300,
                  },
                },
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left",
                },
                transformOrigin: {
                  vertical: "top",
                  horizontal: "left",
                },
                getcontentanchorel : null,
              }}
              open={cardCategoryOpen}
              onOpen={() => setCardCategoryOpen(true)}
              onClose={() => setCardCategoryOpen(false)}
            >
              {userCategories?.map((category, index) => (
                <MenuItem key={index} value={category?.userCategory}>
                  {category?.userCategory}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl fullWidth margin="normal">
            <InputLabel id="type-label">Type</InputLabel>
            <Select
              labelId="type-label"
              value={request?.typeFilter[0] || ""}
              onChange={(e) => {
                e.preventDefault();
                handleRequestChange("typeFilter", e.target.value);
                setTypeOpen(false);
              }}
              input={<OutlinedInput label="Type" />}
              open={typeOpen}
              onOpen={() => setTypeOpen(true)}
              onClose={() => setTypeOpen(false)}
            >
              <MenuItem
                value="deselect"
                sx={{ fontStyle: "italic", color: "grey" }}
              >
                Clear Selection
              </MenuItem>
              {cardType?.map((card, index) => (
                <MenuItem key={index} value={card?.value}>
                  {card?.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <div style={{ marginTop: "15px" }}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <Typography>Select Date</Typography>
              <IconButton onClick={() => setUseDateRange(!useDateRange)}>
                {useDateRange ? (
                  <ToggleOnIcon sx={{ fontSize: 44, color: "#0077B6" }} />
                ) : (
                  <ToggleOffIcon sx={{ fontSize: 44, color: "#0077B6" }} />
                )}
              </IconButton>
              <Typography>Select Date Range</Typography>
            </div>

            {useDateRange ? (
              <>
                <TextField
                  type="date"
                  label="Start Date"
                  value={request?.dateFilter?.startDate || ""}
                  onChange={(e) => {
                    e.preventDefault();
                    handleRequestChange("startDate", e.target.value);
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  sx={{ marginRight: 2, marginTop: 1 }}
                />
                <TextField
                  type="date"
                  label="End Date"
                  value={request?.dateFilter?.endDate || ""}
                  onChange={(e) => {
                    e.preventDefault();
                    handleRequestChange("endDate", e.target.value);
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  sx={{ marginTop: 1 }}
                />
              </>
            ) : (
              <TextField
                type="date"
                label="Select Date"
                value={request?.dateFilter?.startDate || ""}
                onChange={(e) => {
                  e.preventDefault();
                  handleRequestChange("bothDates", e.target.value);
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                sx={{ marginTop: 1 }}
              />
            )}
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={(e) => {
              e.preventDefault();
              setFilterView(false);
              checkForResetFilter() && handleRequestChange("filterReset");
            }}
          >
            Reset Filters
          </Button>
          <Button onClick={handleSubmit} disabled={handleApplyFilterDisabled()}>
            Apply Filters
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default FilterPopup;
