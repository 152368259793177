import { Routes, Route, BrowserRouter } from "react-router-dom";
import LoginContainer from "./login/LoginContainer";
import DashboardContainer from "./dashboard/DashboardContainer";
import { useEffect } from "react";
import { refreshToken } from "./config/firebase/firebase";

function App() {

  useEffect(() => {
    // console.log("REFRESH TOKEN CALLED");
    refreshToken()
    setInterval(refreshToken, 3580 * 1000);
  }, []);

  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<LoginContainer />} />
          <Route path="/dashboard" element={<DashboardContainer/>} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
