import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Switch, TextField, MenuItem, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { Box } from '@mui/system';

const FilterPopup = ({ 
    onClose, 
    handleSubmit, 
    startDate, 
    setStartDate, 
    endDate, 
    setEndDate, 
    dataProcess, 
    setDataProcess 
}) => {
    const [dateRange, setDateRange] = useState(false);

    const handleSwitchChange = (event) => {
        setDateRange(event.target.checked);
    };

    const handleDataProcessChange = (event) => {
        setDataProcess(event.target.value);
    };

    const handleStartDateChange = (event) => {
        setStartDate(event.target.value);
    };

    const handleEndDateChange = (event) => {
        setEndDate(event.target.value);
    };

    const handleResetFilters = () => {
        setDateRange(false);
        setDataProcess('');
        setStartDate('');
        setEndDate('');
    };

    const handleClose = () => {
        handleResetFilters();
        onClose();
    };

    const handleFinalSubmit = () => {
        handleSubmit();
        onClose();
    }

    return (
        <Dialog open={true} onClose={handleClose}>
            <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                Filter Options
                <IconButton onClick={handleClose}>
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <TextField
                    select
                    label="Data Process"
                    variant="outlined"
                    fullWidth
                    value={dataProcess}
                    onChange={handleDataProcessChange}
                    sx={{ marginBottom: 2 }}
                >
                    <MenuItem value="success">Success</MenuItem>
                    <MenuItem value="inProcess">In Process</MenuItem>
                    <MenuItem value="failure">Failed</MenuItem>
                </TextField>

                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mt: 2, mb: 2 }}>
                    <span>Select Date</span>
                    <Switch
                        checked={dateRange}
                        onChange={handleSwitchChange}
                        color="primary"
                    />
                    <span>Select Date Range</span>
                </Box>

                <TextField
                    label="Start Date"
                    type="date"
                    variant="outlined"
                    fullWidth
                    value={startDate}
                    onChange={handleStartDateChange}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    sx={{ marginBottom: 2 }}
                />

                {dateRange && (
                    <TextField
                        label="End Date"
                        type="date"
                        variant="outlined"
                        fullWidth
                        value={endDate}
                        onChange={handleEndDateChange}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        sx={{ marginBottom: 2 }}
                    />
                )}
            </DialogContent>
            <DialogActions sx={{ justifyContent: 'space-between' }}>
                <Button onClick={handleResetFilters} color="primary">
                    RESET FILTERS
                </Button>
                <Button onClick={handleFinalSubmit} color="primary" variant="contained">
                    APPLY FILTERS
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default FilterPopup;
