import React, { useEffect, useState } from "react";
import SettingsContainer from "./settingsPage/SettingsContainer";
import apiHelper from "../apiCentral/apiHelper";
import { path } from "../apiCentral/path";
import CardsContainer from "./cards/CardsContainer";
import AnalyticsContainer from "./analytics/AnalyticsContainer";
import  DraftContainer from "./drafts/DraftContainer";
import { aggregateDatatoDateFromTimestamp, getDatesList } from "../utils";
import { useLocation, useNavigate } from "react-router-dom";
import Cookies from 'js-cookie';
import Button from '@mui/material/Button';
import Analytics from '@mui/icons-material/Analytics';
import CreditCard from '@mui/icons-material/CreditCard';
import Bookmark from '@mui/icons-material/Bookmark';
import Settings from '@mui/icons-material/Settings';
import ExitToApp from '@mui/icons-material/ExitToApp';
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';

import './dashboardContainer.css';
import { Box, Typography } from "@mui/material";
import { categoryColors } from "../contants";
const _ = require("lodash");

function DashboardContainer() {
  const navigate = useNavigate();
  const location = useLocation();
  const [selectedComponent, setSelectedComponent] = useState("analytics");

  const [users, setUsers] = useState(null);
  const [orgDisplayName, setOrgDisplayName] = useState(null);
  const [userType, setUserType] = useState(null);
  const [usersMap, setUsersMap] = useState(null);
  const [totalUsers, setTotalUsers] = useState(null);

  const [userCategories, setUserCategories] = useState(null);
  const [userCategoriesColorMap, setUserCategoriesColorMap] = useState(null);
  const [totalUserCategories, setTotalUserCategories] = useState(null);
  const [cardSourceDistribution, setCardSourceDistribution] = useState(null);
  const [cardScanDistribution, setCardScanDistribution] = useState(null);
  // Later to Make it responsive based on the data or user prefeered choice
  const [cardScanPeriodType, setCardScanPeriodType] = useState(null);
  const [periodWiseCardScanDistribution, setPeriodWiseCardScanDistribution] = useState(null);
  const [companyCardCount, setCompanyCardCount] = useState(null);
  const [topEmployeeChart, setTopEmployeeChart] = useState(null);
  // Later to Make it responsive based on the data or user prefeered choice
  const [topEmployeeChartPeriodType, setTopEmployeeChartPeriodType] = useState(null);
  const [periodWiseTopEmployeeChart, setPeriodWiseTopEmployeeChart] = useState(null);
  const [totalCards, setTotalCards] = useState(null);
  const [cards, setCards] = useState(null);

  const [allInitialApiLoaded, setAllInitialApiLoaded] = useState(false);

  useEffect(() => {
    const token = Cookies.get('token');
    if (_.isEmpty(token)) {
      navigate('/');
      return;
    }
    const orgDisplayNameInCookie = Cookies.get('orgDisplayName');
    if (!_.isEmpty(orgDisplayNameInCookie)) {
      setOrgDisplayName(orgDisplayNameInCookie);
    }
    const userTypeInCookie = Cookies.get('userType');
    if (!_.isEmpty(userTypeInCookie)) {
      setUserType(userTypeInCookie);
    }
    const queryParams = new URLSearchParams(location?.search);
    const currentTab = queryParams.get('tab');
    setSelectedComponent(currentTab || "analytics");
    handleGetUserCategoryCall();
    initialApiCalls();
  }, []);

  useEffect(() => {
    if (!_.isEmpty(cardScanDistribution)) {
      try {
        let scanCardResults = aggregateDatatoDateFromTimestamp(cardScanDistribution?.scan, null, null);
        let contactCardResults = aggregateDatatoDateFromTimestamp(cardScanDistribution?.contact, scanCardResults?.minDate, scanCardResults?.maxDate);
        let minDate = contactCardResults?.minDate;
        let maxDate = contactCardResults?.maxDate;
        // console.log("AGGREGATED SCAN CARD DATA: ", JSON.stringify(scanCardResults?.aggregatedData));
        // console.log("AGGREGATED CONTACT CARD DATA: ", JSON.stringify(contactCardResults?.aggregatedData));
        // console.log("MIN DATE: ", minDate);
        // console.log("MAX DATE: ", maxDate);
        let labels = getDatesList(minDate, maxDate);
        let scanData = [];
        let contactData = [];
        labels.forEach((label) => {
          if (scanCardResults?.aggregatedData?.[label]) {
            scanData.push(scanCardResults.aggregatedData[label]);
          } else {
            scanData.push(0);
          }
          if (contactCardResults?.aggregatedData?.[label]) {
            contactData.push(contactCardResults.aggregatedData[label]);
          } else {
            contactData.push(0);
          }
        });
        // console.log("LABELS: " + labels);
        // console.log("SCAN DATA: " + scanData);
        // console.log("CONTACT DATA: " + contactData);
        setPeriodWiseCardScanDistribution({
          labels: labels,
          data: {
            scan: scanData,
            contact: contactData
          }
        });
        // console.log("PERIOD WISE CARD SCAN DISTRIBUTION: ", {
        //   labels: labels,
        //   data: {
        //     scan: scanData,
        //     contact: contactData
        //   }
        // });
      } catch (error) {
        console.error("ERROR in Createg Data for Scan Card Distribution Chart: ", error);
      }
    } else {
      setPeriodWiseCardScanDistribution(null);
    }
  }, [cardScanDistribution]);

  useEffect(() => {
    if (!_.isEmpty(topEmployeeChart) && !_.isEmpty(usersMap)) {
      try {
        let minDate = null;
        let maxDate = null;
        let aggregatedDataList = Object.keys(topEmployeeChart).map((user_id) => {
          let result = aggregateDatatoDateFromTimestamp(topEmployeeChart[user_id], minDate, maxDate);
          if (!minDate || minDate > result?.minDate) {
            minDate = result?.minDate;
          }
          if (!maxDate || maxDate < result?.maxDate) {
            maxDate = result?.maxDate;
          }
          return { dataLabel: usersMap[user_id]?.displayName, dataValue: result?.aggregatedData };
        });
        // console.log("AGGREGATED DATA LIST: ", JSON.stringify(aggregatedDataList));
        // console.log("MIN DATE: ", minDate);
        // console.log("MAX DATE: ", maxDate);

        let labels = getDatesList(minDate, maxDate);
        aggregatedDataList.forEach((employeeData) => {
          let dataPoints = [];
          labels.forEach((label) => {
            if (employeeData?.dataValue?.[label]) {
              dataPoints.push(employeeData?.dataValue?.[label]);
            } else {
              dataPoints.push(0);
            }
          });
          employeeData.dataValue = dataPoints;
        });
        // console.log("AGGREGATED DATA LIST FINAL: ", JSON.stringify(aggregatedDataList));
        setPeriodWiseTopEmployeeChart({
          labels: labels,
          data: aggregatedDataList
        });
      } catch (error) {
        console.error("ERROR in Createg Data for TOP Employee Chart: ", error);
      }
    } else {
      setPeriodWiseTopEmployeeChart(null);
    }
  }, [topEmployeeChart, usersMap]);

  useEffect(() => {
    if (!_.isEmpty(userCategories)) {
      let colorMap = {};
      for(let i = 0; i < Math.min(20, userCategories.length); i++) {
        colorMap[userCategories?.[i]?.userCategory] = categoryColors[i];
      }
      setUserCategoriesColorMap(colorMap);
    }
  }, [userCategories]);

  const initialApiCalls = () => {
    setUsers(null);
    setTotalUsers(null);
    setCards(null);
    setTotalCards(null);
    setTopEmployeeChart(null);
    setCardScanDistribution(null);
    setCardSourceDistribution(null);
    setCompanyCardCount(null);
    setAllInitialApiLoaded(false);
    Promise.all([
      handleGetUserCall(),
      handleGetCardsCall(),
      handleTopEmployeeChartCall(),
      handleGetCardScanDistributionCall(),
      handleGetCardAggregationCall(),
    ]).then(() => {
      setAllInitialApiLoaded(true);
    }).catch((error) => {
      console.error("Error in API calls", error);
      setAllInitialApiLoaded(true);
    });
  };

  const handleGetUserCall = async () => {
    let response;
    try {
      response = await apiHelper.get(path.getUsers, {});
      if (!_.isEmpty(response?.data?.results?.users)) {
        let map = response.data.results.users.reduce((acc, user) => {
          if (user.uid) {
            acc[`users/${user.uid}`] = {
              displayName: user?.display_name,
              gender: user?.gender
            };
          }
          return acc;
        }, {});
        if (!_.isEmpty(map)) {
          setUsersMap(map);
        }
      }
      setUsers(response?.data?.results?.users);
      setTotalUsers(response?.data?.total);
    } catch (error) {
      console.error(`Error occurred in handleGetUserCall: ${error}`);
      alert("Internal Server Error! Please Try Again");
    }
  };

  const handleGetCardsCall = async () => {
    let response;
    let request = {
      pagination: {
        page: 0,
        size: 5,
      },
    };
    try {
      response = await apiHelper.post(path?.getCards, request);
      setCards(response?.data?.results?.cards);
      setTotalCards(response?.data?.total);
    } catch (error) {
      console.error(`Error occurred in handleGetCardsCall: ${error}`);
      alert("Internal Server Error! Please Try Again");
    }
  };

  const handleGetCardScanDistributionCall = async () => {
    let response;
    try {
      response = await apiHelper.get(path?.cardScanDistributionChart);
      if(!_.isEmpty(response?.data?.results)) {
        setCardScanDistribution(response?.data?.results);
      }
    } catch (error) {
      console.error(`Error occurred in handleGetCardScanDistributionCall: ${error}`);
      alert("Internal Server Error! Please Try Again");
    }
  };

  const handleTopEmployeeChartCall = async () => {
    let response;
    try {
      response = await apiHelper.get(path?.topEmployeeChart);
      if(!_.isEmpty(response?.data?.results)) {
        setTopEmployeeChart(response?.data?.results);
      }
    } catch (error) {
      console.error(`Error occurred in handleTopEmployeeChartCall: ${error}`);
      alert("Internal Server Error! Please Try Again");
    }
  };

  const handleGetCardAggregationCall = async () => {
    let response;
    try {
      response = await apiHelper.get(path.getCardAggregation);
      // console.log("CARD AGGREGATION: ", JSON.stringify(response?.data?.results));
      if (!_.isEmpty(response?.data?.results?.type)) {
          setCardSourceDistribution(response?.data?.results?.type);
      } 
      if (!_.isEmpty(response?.data?.results?.company_name)) {
        setCompanyCardCount(response?.data?.results?.company_name);
      }
    } catch (error) {
      console.error(
        `Error occurred in handleGetCardAggregationCall: ${error}`
      );
      alert("Internal Server Error! Please Try Again");
    }
  };

  const handleGetUserCategoryCall = async () => {
    let userCategoryResponse;
    try {
      userCategoryResponse = await apiHelper.get(path.getUserCategory, {});
      if(!_.isEmpty(userCategoryResponse?.data?.results?.userCategories)) {
        setUserCategories(userCategoryResponse?.data?.results?.userCategories);
        setTotalUserCategories(userCategoryResponse?.data?.total);
      }
    } catch (error) {
      alert("Internal Server Error! Please Try Again");
    }
  };

  const handleComponentChange = (e, componentName) => {
    e.preventDefault();
    setSelectedComponent(componentName);
    navigate(`/Dashboard?tab=${componentName}`);
  };

  const handleLogout = async (e) => {
    try {
      navigate('/');
      Cookies.remove('token');
    } catch (error) {
      console.error(`Error occurred in handleLogout: ${error}`);
      alert("Internal Server Error! Please Try Again");
    }
  };

  const checkAtleastOneCardAndOneUser = () => {
    // Will only check if all Inital Api's are loaded is true
    if (!allInitialApiLoaded) return true;
    if (totalCards && totalCards > 0 && totalUsers && totalUsers > 0) {
      return true;
    }
    return false;
  };

  return (
    <>
      <div className="dashboard-container">
        <div className="menu-container">
          <div className="menu-buttons">
            <Button
              startIcon={<Analytics />}
              onClick={(e) => handleComponentChange(e, "analytics")}
              disabled={!checkAtleastOneCardAndOneUser()}
              sx={{
                textTransform: "none",
                fontSize: "1rem",
                justifyContent: "flex-start",
                borderTopRightRadius: "20px",
                borderBottomRightRadius: "20px"
              }}
              style={{
                width: "100%",
                color: checkAtleastOneCardAndOneUser() ? "black" : "grey",
                backgroundColor: selectedComponent === "analytics"
                  && checkAtleastOneCardAndOneUser() ? "#F0F8FF" : "inherit",
                borderLeft:
                  selectedComponent === "analytics" && checkAtleastOneCardAndOneUser()
                    ? "5px solid #5D5FEF"
                    : "inherit",
              }}
            >
              Analytics
            </Button>
            <Button
              startIcon={<CreditCard />}
              onClick={(e) => handleComponentChange(e, "cards")}
              disabled={!checkAtleastOneCardAndOneUser()}
              style={{
                width: "100%",
                color: checkAtleastOneCardAndOneUser() ? "black" : "grey",
                backgroundColor: selectedComponent === "cards"
                  && checkAtleastOneCardAndOneUser() ? "#F0F8FF" : "inherit",
                borderLeft:
                  selectedComponent === "cards" && checkAtleastOneCardAndOneUser()
                    ? "5px solid #5D5FEF"
                    : "inherit",
              }}
              sx={{
                textTransform: "none",
                fontSize: "1rem",
                justifyContent: "flex-start",
                borderTopRightRadius: "20px",
                borderBottomRightRadius: "20px",
              }}
            >
              Cards
            </Button>
            <Button
              startIcon={<Bookmark />}
              onClick={(e) => handleComponentChange(e, "bookmarkedCards")}
              disabled={!checkAtleastOneCardAndOneUser()}
              style={{
                width: "100%",
                color: checkAtleastOneCardAndOneUser() ? "black" : "grey",
                backgroundColor: selectedComponent === "bookmarkedCards"
                  && checkAtleastOneCardAndOneUser() ? "#F0F8FF" : "inherit",
                borderLeft:
                  selectedComponent === "bookmarkedCards" && checkAtleastOneCardAndOneUser()
                    ? "5px solid #5D5FEF"
                    : "inherit",
              }}
              sx={{
                textTransform: "none",
                fontSize: "1rem",
                justifyContent: "flex-start",
                borderTopRightRadius: "20px",
                borderBottomRightRadius: "20px",
              }}
            >
              Bookmark
            </Button>
            <Button
              startIcon={<InsertDriveFileOutlinedIcon />}
              onClick={(e) => handleComponentChange(e, "drafts")}
              style={{
                width: "100%",
                color: "black",
                backgroundColor:
                  selectedComponent === "drafts" || !checkAtleastOneCardAndOneUser() ? "#F0F8FF" : "inherit",
                borderLeft:
                  selectedComponent === "drafts" || !checkAtleastOneCardAndOneUser()
                    ? "5px solid #5D5FEF"
                    : "inherit",
              }}
              sx={{
                textTransform: "none",
                fontSize: "1rem",
                justifyContent: "flex-start",
                borderTopRightRadius: "20px",
                borderBottomRightRadius: "20px",
              }}
            >
              Draft
            </Button>
            <Button
              startIcon={<Settings />}
              onClick={(e) => handleComponentChange(e, "settings")}
              style={{
                width: "100%",
                color: "black",
                backgroundColor:
                  selectedComponent === "settings" || !checkAtleastOneCardAndOneUser() ? "#F0F8FF" : "inherit",
                borderLeft:
                  selectedComponent === "settings" || !checkAtleastOneCardAndOneUser()
                    ? "5px solid #5D5FEF"
                    : "inherit",
              }}
              sx={{
                textTransform: "none",
                fontSize: "1rem",
                justifyContent: "flex-start",
                borderTopRightRadius: "20px",
                borderBottomRightRadius: "20px",
              }}
            >
              Settings
            </Button>
          </div>
          <div className="logout-button">
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-end',
                alignSelf: 'flex-start',
                borderRadius: '8px',
                padding: '20px',
                backgroundColor: 'white',
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
              }}
            >
              {orgDisplayName && (
                <Typography
                  sx={{ fontSize: "1rem", fontWeight: "bold" }}
                >
                  {orgDisplayName}
                </Typography>
              )}
              {userType === "ORG" && (
                <Typography
                  sx={{ color: "#808080" }}
                >
                  Admin
                </Typography>
              )}
            </Box>
            <Button
              className="logout-button"
              startIcon={<ExitToApp />}
              onClick={handleLogout}
              sx={{ textTransform: "none", fontSize: "1rem" }}
            >
              Log out
            </Button>
          </div>
        </div>
        <div className="component-display">
          {selectedComponent === "analytics" &&
          checkAtleastOneCardAndOneUser() ? (
            <>
              <AnalyticsContainer
                cardScanDistribution={cardScanDistribution}
                periodWiseCardScanDistribution={periodWiseCardScanDistribution}
                cardSourceDistribution={cardSourceDistribution}
                companyCardCount={companyCardCount}
                totalCards={totalCards}
                cards={cards}
                topEmployeeChart={topEmployeeChart}
                periodWiseTopEmployeeChart={periodWiseTopEmployeeChart}
                totalUsers={totalUsers}
                usersMap={usersMap}
                userCategoriesColorMap={userCategoriesColorMap}
                initialApiCalls={initialApiCalls}
              />
            </>
          ) : selectedComponent === "cards" &&
            checkAtleastOneCardAndOneUser() ? (
            <>
              <CardsContainer
                isBookmarked={false}
                usersMap={usersMap}
                userCategories={userCategories}
                userCategoriesColorMap={userCategoriesColorMap}
              />
            </>
          ) : selectedComponent === "bookmarkedCards" &&
            checkAtleastOneCardAndOneUser() ? (
            <>
              <CardsContainer
                isBookmarked={true}
                usersMap={usersMap}
                userCategories={userCategories}
                userCategoriesColorMap={userCategoriesColorMap}
              />
            </>
          ) : selectedComponent === "drafts" && checkAtleastOneCardAndOneUser() ? (
            <>
              <DraftContainer
                userCategories={userCategories}
                userCategoriesColorMap={userCategoriesColorMap}
              />
            </>
          ):selectedComponent === "settings" ||
            !checkAtleastOneCardAndOneUser() ? (
            <>
              <SettingsContainer
                users={users}
                setUsers={setUsers}
                handleGetUserCall={handleGetUserCall}
                handleGetUserCategoryCall={handleGetUserCategoryCall}
                userCategories={userCategories}
                userCategoriesColorMap={userCategoriesColorMap}
                setUserCategories={setUserCategories}
              />
            </>
          ) : (
            <>
              <h1>Nothing Selected</h1>
            </>
          )}
        </div>
      </div>
    </>
  );
}

export default DashboardContainer;
