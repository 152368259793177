import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import Button from "@mui/material/Button";
import { TextField } from "@mui/material";

import {
  Paper,
  Box,
  Grid,
  Typography,
  InputAdornment,
  IconButton,
} from "@mui/material";


import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

import apiHelper from "../../../apiCentral/apiHelper";
import { saveAs } from "file-saver";
const { path } = require("../../../apiCentral/path");
const _ = require("lodash");

function ForgotPasswordPopup({
  user,
  isPopupOpen,
  handlePopupClose
}) {
  const [data, setData] = useState({
    password: "",
    confirmPassword: "",
  });
  const [loading, setLoading] = useState(false);
  const handleInputChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setData({
      ...data,
      [name]: value,
    });
  };

  const [showPassword, setShowPassword] = useState({
    newPassword: false,
    confirmPassword: false
  });

  const validatePassword = (password) => {
    if (!password) return true;
    return password.length >= 6;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (data.password !== data.confirmPassword) {
      alert("Password and confirm password should match.");
      return;
    }
    let response;
    try {
      if (!validatePassword(data?.password)) {
        alert("Please enter valid details");
        return;
      }
      setLoading(true);
      response = await apiHelper.put(path?.resetAgentPassword + "/" + user?.uid, data);
      const fileName = `AGENT_${new Date().toISOString()}.txt`;
      const fileContent = `Email: ${user?.email}\nPassword: ${data?.password}`;
      const blob = new Blob([fileContent], { type: 'text/plain;charset=utf-8' });
      saveAs(blob, fileName);
      setLoading(false);
      handlePopupClose();
    } catch (error) {
      console.error(`Error occurred in handleSubmit: ${error}`);
      alert("Internal Server Error! Please Try Again");
    }
  };

  const handleClickShowPassword = (field) => {
    setShowPassword({ ...showPassword, [field]: !showPassword[field] });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };


  return (
    <>
      <Dialog open={isPopupOpen}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            position: "relative",
            width: "520px"
          }}
        >
        </Box>
        <Paper
          sx={{
            overflow: "hidden",
            border: "1px solid #d0d0d0",
            height: "370px",
            padding: "20px",

          }}
        >
          <form onSubmit={handleSubmit}>
            <h3 style={{ marginBottom: "20px" }}>Change Employee Password</h3>
            <Grid container direction="column" spacing={2}>
              <Grid item >
                <Typography variant="subtitle1" gutterBottom>
                  New Password
                </Typography>
                <TextField
                  name="password"
                  type={showPassword.newPassword ? 'text' : 'password'}
                  placeholder="Enter Your New Password"
                  fullWidth
                  onChange={handleInputChange}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() => handleClickShowPassword('newPassword')}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword?.newPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                  error={!validatePassword(data?.password)}
                  helperText={!validatePassword(data?.password) ? "Password should be at least 6 characters long" : ""}
                  required
                />
              </Grid>
              <Grid item style={{ marginTop: '10px' }}>
                <Typography variant="subtitle1" gutterBottom>
                  Confirm New Pasword
                </Typography>
                <TextField
                  name="confirmPassword"
                  type={showPassword.confirmPassword ? 'text' : 'password'}
                  placeholder="Enter Your New Password"
                  fullWidth
                  onChange={handleInputChange}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() => handleClickShowPassword('confirmPassword')}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword?.confirmPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                  error={!validatePassword(data?.confirmPassword)}
                  helperText={!validatePassword(data?.confirmPassword) ? "Password should be at least 6 characters long" : ""}
                  required
                />
              </Grid>
              <Grid item container justifyContent="center" sx={{ marginTop: "20px",columnGap:"25px" }}>
                <Button onClick={handlePopupClose}>
                  Cancel
                </Button>
                <Button variant="contained" color="primary" type="submit" disabled={loading}>
                  Reset Password
                </Button>
              </Grid>
            </Grid>
          </form>
        </Paper>
      </Dialog>

    </>
  );
}

export default ForgotPasswordPopup;
