import React, { useState } from "react";
import {
    Paper,
    Button,
    Box,
    TextField,
    Grid,
    Typography,
    InputAdornment,
    IconButton,
} from "@mui/material";
import apiHelper from "../../apiCentral/apiHelper";
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { path } from "../../apiCentral/path";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
const _ = require("lodash");

function AdminPasswordChangeContainer() {
    const [data, setData] = useState({
        newPassword: "",
        confirmPassword: "",
    });
    const [showPassword, setShowPassword] = useState({
        newPassword: false,
        confirmPassword: false
    });
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate()

    const validatePassword = (password) => {
        if (!password) return true;
        return password.length >= 6;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        let response;
        setLoading(true);
        try {
            if (!validatePassword(data?.newPassword) || !validatePassword(data?.confirmPassword)) {
                alert("Please enter valid details");
                setLoading(false);
                return;
            }
            if (data?.newPassword !== data?.confirmPassword) {
                alert("Password and Confirm Password do not match");
                setLoading(false);
                return;
            }
            response = await apiHelper.put(path?.resetAdminPassword, {
                password: data?.newPassword
            });
            alert("Password changed successfully, Please login Again");
            navigate('/');
            Cookies.remove('token');
        } catch (error) {
            alert("Internal Server Error! Please Try Again");
            console.error(`Error occurred in handleSubmit: ${error}`);
        }
        setLoading(false);
    };

    const handleInputChange = (e) => {
        e.preventDefault();
        const { name, value } = e.target;
        setData({
            ...data,
            [name]: value,
        });
    };

    const handleClickShowPassword = (field) => {
        setShowPassword({ ...showPassword, [field]: !showPassword[field] });
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    return (
        <>
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginTop: "40px",
                    marginBottom: "20px",
                    position: "relative",
                    height: "45px",
                }}
            >
                <h3 style={{ margin: 0 }}>Reset Admin Password</h3>
            </Box>
            <Paper
                sx={{
                    overflow: "hidden",
                    border: "1px solid #d0d0d0",
                    height: "300px",
                    padding: "20px",

                }}
            >
                <form onSubmit={handleSubmit}>
                    <Grid container direction="column" spacing={2}>
                        <Grid item >
                            <Typography variant="subtitle1" gutterBottom>
                                New Password
                            </Typography>
                            <TextField
                                name="newPassword"
                                type={showPassword.newPassword ? 'text' : 'password'}
                                placeholder="Enter Your New Password"
                                fullWidth
                                onChange={handleInputChange}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                onClick={() => handleClickShowPassword('newPassword')}
                                                onMouseDown={handleMouseDownPassword}
                                                edge="end"
                                            >
                                                {showPassword?.newPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    )
                                }}
                                error={!validatePassword(data?.newPassword)}
                                helperText={!validatePassword(data?.newPassword) ? "Password should be at least 6 characters long" : ""}
                                required
                            />
                        </Grid>
                        <Grid item>
                            <Typography variant="subtitle1" gutterBottom>
                                Confirm New Pasword
                            </Typography>
                            <TextField
                                name="confirmPassword"
                                type={showPassword.confirmPassword ? 'text' : 'password'}
                                placeholder="Enter Your New Password"
                                fullWidth
                                onChange={handleInputChange}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                onClick={() => handleClickShowPassword('confirmPassword')}
                                                onMouseDown={handleMouseDownPassword}
                                                edge="end"
                                            >
                                                {showPassword?.confirmPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    )
                                }}
                                error={!validatePassword(data?.confirmPassword)}
                                helperText={!validatePassword(data?.confirmPassword) ? "Password should be at least 6 characters long" : ""}
                                required
                            />
                        </Grid>
                        <Grid item container justifyContent="center" sx={{ marginTop: "4px" }}>
                            <Button variant="contained" color="primary" type="submit" disabled={loading}>
                                Reset Password
                            </Button>
                        </Grid>
                    </Grid>
                </form>
            </Paper>
        </>
    );
}

export default AdminPasswordChangeContainer;
