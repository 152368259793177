const categoryColors = [
  "#D35B73", 
  "#4FC3F7", 
  "#BA68C8", 
  "#FFB74D", 
  "#81C784", 
  "#E88799", 
  "#5A9BBF", 
  "#A2708C", 
  "#F39523", 
  "#5EAC8E", 
  "#C57696", 
  "#837CB4", 
  "#8793E0", 
  "#FFD54F", 
  "#98A679", 
  "#FF9A91",
  "#5870AE", 
  "#45C5C5", 
  "#BAA126", 
  "#297045",  
  "#C87353",
  "#5E5EDF",
  "#6F5BB6",
  "#9A8E9A",
];

const cardType = [{
    label: "Scanned",
    value: "scan"
}, {
    label: "Contact",
    value: "contact"
}];

const cardTypeMap = {
    scan: {
      label: "Scanned",
      color: "#009688",
    },
    contact: {
      label: "Contact",
      color: "#F39523",
    },
  };

module.exports = {
    categoryColors,
    cardType,
    cardTypeMap
}