import React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { CircularProgress } from "@mui/material";
import credit from "../../../assets/images/credit-card.png";
import employees from "../../../assets/images/employees.png";
import './totalCards.css';

function CountCard({ count, label, variant }) {
  return (
    <>
      {count ? (
        <Card className="card-container" style={{ backgroundColor: variant === 'totalCards' ? 'rgba(206 112 255 / 70%)' : 'rgba(126 188 254 / 70%)' }}>
          <CardContent style={{ width: '100%', height: '100%', padding: 0 }}>
            <Typography className="count-card-text">{label}</Typography>
            <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
              <Typography
                className="count-card-number"
              >
                {count}
              </Typography>
            </div>
            {
              variant === 'totalCards' ? 
                <img src={credit} alt="Credit Card" className="footer-image" /> : 
                <img src={employees} alt="Employees" className="footer-image" />
            }
          </CardContent>
        </Card>
      ) : (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', width: '100%' }}>
          <CircularProgress />
        </div>
      )}
    </>
  );
}

export default CountCard;
