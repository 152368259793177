import React, { useRef, useState } from "react";
import { auth } from "../config/firebase/firebase";
import { signInWithEmailAndPassword } from "firebase/auth";
import axios from "axios";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import { path } from "../apiCentral/path";
import { CircularProgress } from "@mui/material";
import "./Login.css";
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import login from '../assets/images/login.png';
const _ = require("lodash");

function LoginContainer() {
  const email = useRef();
  const password = useRef();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword); // Toggle between true and false
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    // Firebase Authentication
    let signInRes;
    try {
      signInRes = await signInWithEmailAndPassword(
        auth,
        email?.current?.value,
        password?.current?.value
      );
    } catch (error) {
      handleAuthError(error);
      return;
    }
    try {
      // console.log("SIGN IN RES: " + JSON.stringify(signInRes));
      let expiresIn = Number(signInRes._tokenResponse.expiresIn);
      expiresIn = expiresIn - 10;
      const expirationDate = new Date(new Date().getTime() + expiresIn * 1000);
      Cookies.set("token", signInRes._tokenResponse.idToken, {
        expires: expirationDate,
      });
    } catch (error) {
      console.error("Error persisting token in Cookies: ", error);
    }

    //Backend Authentication
    let response;
    try {
      response = await axios.post(
        path?.loginUrl, {},
        {
          headers: {
            authorization: `${Cookies.get("token")}`,
          },
        }
      );

      if (response?.status === 200) {
        Cookies.remove("orgDomain");
        Cookies.remove("orgDisplayName");
        Cookies.remove("userType");
        // console.log("REQUEST SUCCESSFUL:", response.data);
        if (!_.isEmpty(response?.data?.orgDomain)) {
          Cookies.set("orgDomain", response?.data?.orgDomain, {
            expires: new Date(new Date().getTime() + 1000 * 60 * 60 * 24 * 365 * 10)
          });
        }
        if (!_.isEmpty(response?.data?.orgDisplayName)) {
          Cookies.set("orgDisplayName", response?.data?.orgDisplayName, {
            expires: new Date(new Date().getTime() + 1000 * 60 * 60 * 24 * 365 * 10)
          });
        }
        if (!_.isEmpty(response?.data?.userType)) {
          Cookies.set("userType", response?.data?.userType, {
            expires: new Date(new Date().getTime() + 1000 * 60 * 60 * 24 * 365 * 10)
          });
        }
        navigate("/dashboard?tab=analytics");
      } else Cookies.remove("token");
    } catch (error) {
      alert(error?.response?.data);
      Cookies.remove("token");
    }
    setIsLoading(false);
  };

  const handleAuthError = (error) => {
    let errorMessage;

    switch (error.code) {
      case "auth/invalid-email":
        errorMessage = "Invalid email address. Please check and try again.";
        break;
      case "auth/invalid-credential":
        errorMessage = "Invalid Credentials. Please check and try again.";
        break;
      case "auth/user-not-found":
        errorMessage = "No user found with this email.";
        break;
      case "auth/wrong-password":
        errorMessage = "Incorrect password. Please try again.";
        break;
      case "auth/network-request-failed":
        errorMessage =
          "Network error. Please check your internet connection and try again.";
        break;
      default:
        errorMessage = "An unknown error occurred. Please try again later.";
        break;
    }

    console.error("Error signing in:", errorMessage);
    alert(errorMessage);
    setIsLoading(false);
  };

  return (
    <div className="container">
      <div className="left-side">
        <img src={login} alt="Login" />
        <div className="text-overlay">
          Your Cards, Always On Hand...
        </div>
      </div>
      <div className="right-side">
        <div className="login-box">
          <h2>Nice To See You Again</h2>
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <label>Email</label>
              <input type="email" ref={email} placeholder="Enter email" required />
            </div>
            <div className="form-group">
              <label>Password</label>
              <div className="password-field">
                <input
                  type={showPassword ? 'text' : 'password'}
                  ref={password}
                  placeholder="Enter Password"
                  required
                />
                <span className="show-password-icon" onClick={togglePasswordVisibility}>
                  
                </span>
              </div>
            </div>
            <button className="login-button" type="submit" disabled={isLoading}>
              {isLoading ? (
                <div style={{ height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  <CircularProgress style={{ color: 'white' }} size={20} thickness={7} />
                </div>
              ) : `Login`}
            </button>
          </form>
        </div>
      </div>
    </div>
      
  );
}

export default LoginContainer;
