import axios from 'axios';
import Cookies from 'js-cookie';

const api = axios.create({  
  headers: {
    'Content-Type': 'application/json',
  }
});

api.interceptors.request.use(config => {
  const token = Cookies.get('token');
  if (token) {
    config.headers['Authorization'] = `${token}`; 
  } else {
    window.location.href = '/';
    return Promise.reject("TOKEN_NOT_FOUND"); 
  }
  return config;
}, error => {
  return Promise.reject(error);
});

api.interceptors.response.use(
  response => response,
  error => {
    if (error === "TOKEN_NOT_FOUND" || (error.response && error.response.status === 401)) {
      Cookies.remove('token');
      window.location.href = '/';
      return Promise.resolve();
    }
    return Promise.reject(error);
  }
);

const apiHelper = {
  get: (path, queryParams = {}) => {
    return api.get(path, { params: queryParams });
  },

  post: (path, data, queryParams = {}) => {
    return api.post(path, data, { params: queryParams });
  },

  put: (path, data, queryParams = {}) => {
    return api.put(path, data, { params: queryParams });
  },

  delete: (path, queryParams = {}) => {
    return api.delete(path, { params: queryParams });
  }
};

export default apiHelper;