import React, { useEffect, useState } from "react";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Card, CardContent, CircularProgress } from "@mui/material";
const _ = require("lodash");

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const options = {
  plugins: {
    legend: {
      display: false,
    },
  },
  scales: {
    x: {
      grid: {
        display: false,
      },
    },
    y: {
      grid: {
        display: false,
      },
    },
  },
  layout: {
    padding: {
      top: 10, 
    }
  },
  maintainAspectRatio: false
};

const borderColors = [
  "rgb(153, 102, 255)", // Purple
  "rgb(75, 192, 192)", // Cyan
  "rgb(255, 99, 132)", // Red
  "rgb(54, 162, 235)", // Blue
  "rgb(255, 205, 86)", // Yellow
];

const MultiLineChart = ({ data }) => {
  let [finalGraphData, setFinalGraphData] = useState(null);

  useEffect(() => {
    // console.log("DATA : " + JSON.stringify(data));
    if (!_.isEmpty(data)) {
      let labels = data?.labels;
      let linesData = data?.data;
      // console.log("LABELS: " + JSON.stringify(labels));
      // console.log("LINES DATA: " + JSON.stringify(linesData));
      let graphData = {
        labels: labels,
        datasets: [],
      };
      let lines = Math.min(linesData.length, 5);
      for (let i = 0; i < lines; i++) {
        graphData.datasets.push({
          label: linesData[i].dataLabel,
          data: linesData[i].dataValue,
          fill: false,
          borderColor: borderColors[i],
          tension: 0.1,
        });
      }
      setFinalGraphData(graphData);
    } else {
      setFinalGraphData(null);
    }
  }, [data]);

  return data && finalGraphData ? (
    <Card style={{ height: "100%" }}>
      <CardContent style={{ height: "100%" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}
        >
            <Line data={finalGraphData} options={options} />
        </div>
      </CardContent>
    </Card>
  ) : (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        width: "100%",
      }}
    >
      <CircularProgress />
    </div>
  );
};

export default MultiLineChart;
