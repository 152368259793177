import React, { useEffect, useState } from "react";
import { Card, CardContent, CircularProgress, Tooltip, Typography } from "@mui/material";
import './totalCards.css';

// CustomBar component with percentage labels
const HorizontalBarChart = ({ leftNumber, rightNumber, label }) => {
  const [proportionLeft, setProportionLeft] = useState(null);
  const [proportionRight, setProportionRight] = useState(null);

  useEffect(() => {
    if (leftNumber != null && rightNumber != null) {
      let total = leftNumber + rightNumber;
      setProportionLeft(((leftNumber / total) * 100).toFixed(2));
      setProportionRight(((rightNumber / total) * 100).toFixed(2));
    }
  }, [leftNumber, rightNumber]);

  return (
    <>
      {(leftNumber != null && rightNumber != null) ? (
        <Card className="card-container">
          <CardContent style={{ width: '100%', height: '89px', padding: 0, display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
            <Typography className="count-card-text" style={{ paddingLeft: "15px" }}>
              {label}
            </Typography>
            <div
              style={{
                display: "flex",
                width: "96%",
                borderRadius: "5px",
                overflow: "hidden",
                alignItems: "center",
                justifyContent: "center",
                margin: "0 auto",
              }}
            >
              <Tooltip title={<span style={{ color: "white", fontSize: "1rem" }}>Scan</span>} placement="top-end" >
                <div
                  style={{
                    width: `${proportionLeft}%`,
                    backgroundColor: "#009688",
                    height: "55px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Typography
                    className="count-card-number"
                    fontSize={"24px !important"}
                    color={"white"}
                  >
                    {leftNumber == 0 ? "" : leftNumber}
                  </Typography>
                </div>
              </Tooltip>
              <Tooltip title={<span style={{ color: "white", fontSize: "1rem" }}>Contact</span>} placement="top-end" >
                <div
                  style={{
                    width: `${proportionRight}%`,
                    backgroundColor: "#F39523",
                    height: "55px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Typography
                    className="count-card-number"
                    fontSize={"24px !important"}
                    color={"white"}>
                    {rightNumber == 0 ? "" : rightNumber}
                  </Typography>
                </div>
              </Tooltip>
            </div>
          </CardContent>
        </Card>
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            width: "100%",
          }}
        >
          <CircularProgress />
        </div>
      )}
    </>
  );
};

export default HorizontalBarChart;
